import * as Constant from './constants';
import * as Api from '../apis';
import { PaymentType } from '../helpers/constants';
import { navigate } from 'gatsby';

export const getConsultants = (categoryId, hospitalId, isMember, partners=false, isOnlineAndConsultNowAvailable=undefined) => {
  return (dispatch, getState) => {
    dispatch({ type: Constant.APPOINTMENT_CONSULTANTS_GET_REQUEST });
    let cardID = null;
    let partnerId = null;
    if (partners) {
      const { partners } = getState();
      const { cardId } = partners;
      partnerId = partners.partnerId;
      cardID = cardId;
    }

    return Api.getConsultants(categoryId, hospitalId, isMember, cardID, partnerId, partners, isOnlineAndConsultNowAvailable)
      .then(res => {
        dispatch({
          type: Constant.APPOINTMENT_CONSULTANTS_GET_SUCCESS,
          payload: res.results,
        });
        return res;
      })
      .catch(_ => {
        dispatch({
          type: Constant.APPOINTMENT_CONSULTANTS_GET_FAILED,
          payload: [],
        });
      });
  };
};

export const getConsultantsNow = categoryId => {
  return dispatch => {
    dispatch({ type: Constant.APPOINTMENT_CONSULTANTS_NOW_GET_REQUEST });
    return Api.getConsultantsNow(categoryId)
      .then(res => {
        dispatch({
          type: Constant.APPOINTMENT_CONSULTANTS_NOW_GET_SUCCESS,
          payload: res.results,
        });
        return res;
      })
      .catch(_ => {
        dispatch({
          type: Constant.APPOINTMENT_CONSULTANTS_NOW_GET_FAILED,
          payload: [],
        });
      });
  };
};

export const getCategories = (hospitalId, isMember, partners=false) => {
  return (dispatch, getState) => {
    dispatch({ type: Constant.APPOINTMENT_CATEGORIES_GET_REQUEST });
    let cardID = null;
    let partnerId = null;
    if (partners) {
      const { partners } = getState();
      const { cardId } = partners;
      partnerId = partners.partnerId;
      cardID = cardId;
    }
    return Api.getCategories(hospitalId, isMember, cardID, partnerId, partners)
      .then(res => {
        dispatch({
          type: Constant.APPOINTMENT_CATEGORIES_GET_SUCCESS,
          payload: res.results,
        });
        return res;
      })
      .catch(message => {
        dispatch({
          type: Constant.APPOINTMENT_CATEGORIES_GET_FAILED,
          payload: message,
        });
        if (message.message.redirect_flow === 'register') {
          return navigate('/partners/register');
        } else {
          return navigate('/partners/login');
        }
      });
  };
};

export const postSummaryAppointment = () => {
  return (dispatch, getState) => {
    dispatch({ type: Constant.APPOINTMENT_SUMMARY_POST_REQUEST });
    const { orderInfo } = getState();
    const { consultantId, promotionId } = orderInfo;
    const val = {
      consultant_id: consultantId,
      promotion_id: promotionId,
    };
    return Api.postSummaryAppointment(val, null, null, false)
      .then(res => {
        dispatch({
          type: Constant.APPOINTMENT_SUMMARY_POST_SUCCESS,
          payload: res,
        });
        return res;
      })
      .catch(message => {
        dispatch({ type: Constant.APPOINTMENT_SUMMARY_POST_FAILED, message });
        return Promise.reject(message);
      });
  };
};

export const postAppointment = (isMember, partners=false, addOnBenefit) => {
  return (dispatch, getState) => {
    dispatch({ type: Constant.APPOINTMENT_POST_REQUEST });
    const { orderInfo, assessmentInfo, session } = getState();
    const {
      name,
      phone,
      consultNow,
      payment,
      slotTimeId,
      remark,
      consultantId,
      consultCategoryId,
      consentPatientInfo,
      promotionId,
    } = orderInfo;
    let cardID = null;
    let partnerId = null;
    if (isMember) {
      const { memberships } = getState();
      const { cardId } = memberships;
      cardID = Object.keys(cardId)[0];
    }
    if (partners) {
      const { partners } = getState();
      const { cardId } = partners;
      partnerId = partners.partnerId;
      cardID = cardId;
    }

    const val = {
      address: {
        ...orderInfo.address,
        is_save: true,
        customer_name: name,
        phone: phone,
      },
      category: consultCategoryId,
      consultant_id: consultantId,
      remark: remark,
      consent_patient_info: consentPatientInfo,
      user_question_id: null,
      promotion_id: promotionId,
      add_on_benefit: addOnBenefit
    };

    if (!consultNow) {
      val.time_slot_id = slotTimeId;
    }

    if (payment && typeof payment === 'object') {
      val.checkout_method = {
        method: PaymentType.DEFAULT_CREDIT_CARD,
      };
    } else {
      val.checkout_method = {
        method: payment,
      };
    }

    if (assessmentInfo.assessmentId) {
      val.evaluation = assessmentInfo.assessmentId;
    }

    if (!val.checkout_method) {
      return;
    }

    return Promise.resolve()
      .then(() => {
        if (!consultNow && !slotTimeId)
          return Promise.reject('กรุณาเลือกวันที่และเวลา');
        if (!session.user && !session.line && !session.facebook) {
          return Promise.reject('กรุณาเข้าสู่ระบบ');
        }
        return Promise.resolve();
      })
      .then(() => {
        return Api.postAppointment(val, isMember, cardID, partnerId, partners);
      })
      .then(res => {
        const payload = res.data ? res.data : res;
        dispatch({ type: Constant.APPOINTMENT_POST_SUCCESS, payload });
        return payload;
      })
      .catch(message => {
        dispatch({ type: Constant.APPOINTMENT_POST_FAILED, message });
        return Promise.reject(message);
      });
  };
};
