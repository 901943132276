import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from 'gatsby';
import { getConsultants, getCategories } from '../../../actions/appointment';
import { setOrderInfo } from '../../../actions/orderInfo';
import { createAllCategoriesSelector, createConsultantListSelector } from '../../../reducers/appointment';
import { createLoadingSelector } from '../../../reducers/api';
import { getUrlParams } from '../../../helpers/location';
import { isAvailablewithTimeSlot } from '../../../helpers/date';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Select from '@material-ui/core/Select';
import Divider from '@material-ui/core/Divider';
import SchoolOutlined from '@material-ui/icons/SchoolOutlined';
import Avatar from '../../../components/Avatar';
import Layout from '../../../components/v2/Layout';
import DoctorBadge from '../../../components/v2/DoctorBadge';

const useStyles = makeStyles(theme => ({
  categoryImage: {
    width: '100%'
  },
  cardDetail: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'space-between',
    '& h4, p': {
      margin: 0
    },
  },
  chip: {
    height: 16,
    fontSize: '0.67em',
    marginRight: 4
  },
  bottomDetail: {
    color: 'green'
  },
  educationName: {
    display: 'flex',
    flexWrap: 'wrap',
    overflow: 'hidden',
    flexGrow: 1,
    textOverflow: 'ellipsis',
    width: '11rem',
  },
}));

const ConsultantItem = ({ categoryById, consultant, key, onClick }) => {
  const classes = useStyles();
  let online = consultant.is_available;
  const fastestSlot = consultant.fastest_timeslot_datetime;
  if (!online) {
    if (fastestSlot !== null) {
      online = isAvailablewithTimeSlot(fastestSlot);
    }
  }

  return (
    <Grid item xs={12} key={key} style={{ cursor: 'pointer' }} onClick={() => onClick(consultant)}>
      <Box display="flex">
        <Box textAlign="center" flex="1" pt={1}>
          <Avatar online={online} src={consultant?.image} />
        </Box>
        <Box flex="3">
          <Typography variant="subtitle1" component="header" color="textPrimary">
            {consultant.display_name}
          </Typography>
          {consultant.categories.slice(0, 2).map((cat, index) => {
            return (
              <DoctorBadge key={index}>{cat.name}</DoctorBadge>
            );
          })}
          {consultant.medCertAvailable && 
            <DoctorBadge
              color='#FF6060'
            >ใบรับรองแพทย์</DoctorBadge>
          }
          <div  style={{ marginTop: "8px" }}>
            <Grid
              container
              wrap="nowrap"
              direction="row"
              justifyContent="flex-start"
            >
              <Grid item alignItems="center" xs={1}>
                <SchoolOutlined color="primary" fontSize="inherit"/>
              </Grid>
              <Grid item className={classes.educationName}>
                <Typography variant="subtitle2" color="textSecondary" noWrap >
                  {consultant.education || "-"}
                </Typography>
              </Grid>
            </Grid>
          </div>
          <Box style={{ marginTop: "4px" }}>
            <Typography variant="subtitle2" component="p" color="textSecondary">
              {consultant.price} บาท
            </Typography>
          </Box>
        </Box>
      </Box>
      <Divider />
    </Grid>
  );
};

export default ({ location }) => {
  const dispatch = useDispatch();
  const query = getUrlParams(location.search);
  const { categoryId, hospitalId } = query;
  const categoryById = useSelector(state => state.appointment.categoryById);
  const categories = useSelector(createAllCategoriesSelector);
  const consultants = useSelector(createConsultantListSelector);
  const isLoading = useSelector(createLoadingSelector(['appointment/categories/get', 'appointment/consultants/get']));

  React.useEffect(() => {
    dispatch(getCategories(hospitalId));
  }, []);

  React.useEffect(() => {
    if (categories.length && !categoryId) {
      navigateFilter(categories[0].id);
    }
  }, [categories.length]);

  React.useEffect(() => {
    if (categoryId) {
      dispatch(getConsultants(categoryId, hospitalId));
      dispatch(setOrderInfo({ consultCategoryId: categoryId }));
    }
  }, [categoryId]);

  const navigateFilter = id => {
    const category = categories.find(val => `${val.id}` === `${id}`);
    window.logEvent('select_content', { content_type: 'consult_category', item_id: id + '', item_name: category.name });
    let url = `/consult/patientApp/chooseConsultant?categoryId=${id}`;
    if (hospitalId) {
      url += `&hospitalId=${hospitalId}`;
    }
    navigate(url, { replace: true });
  };

  const onChangeFilter = e => {
    navigateFilter(e.target.value);
  };

  const onClickConsultant = consultant => {
    window.logEvent('select_content', { content_type: 'consultant', item_id: consultant.id + '', item_name: consultant.display_name });
    navigate(`/consult/patientApp/appointment?consultantId=${consultant.id}`);
  };

  return (
    <Layout bg="bg4" title="เลือกแพทย์" loading={isLoading && !consultants.length}>
      <Box pb={4}>
        <Select native fullWidth value={categoryId} onChange={onChangeFilter}>
          {categories.map(val => (
            <option value={val.id} key={val.id}>แผนก{val.name}</option>
          ))}
        </Select>
        <Box mt={2}>
          <Grid container spacing={2}>
            {consultants.map(consultant => (
              <ConsultantItem
                categoryById={categoryById}
                consultant={consultant}
                key={consultant.id}
                onClick={onClickConsultant}
              />
            ))}
          </Grid>
        </Box>
      </Box>
    </Layout>
  );
};
